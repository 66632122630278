<template>
  <referentiel-list
    :listTitle="'Liste des Catégorie Rome'"
    :referentielName="referentielName"
    :tableFields="tableFields"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.code"
                @input="handleInput"
                type="text"
                class="form-control"
                id="code"
                name="code"
                placeholder="Code"
              />
              <label for="code">Code</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.libelle"
                @input="handleInput"
                type="text"
                class="form-control"
                id="libelle"
                name="libelle"
                placeholder="Libellé"
              />
              <label for="name">Libellé</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <!-- <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.cheminEmploi"
                @input="handleInput"
                type="text"
                class="form-control"
                id="cheminEmploi"
                name="cheminEmploi"
                placeholder="cheminEmploi"
              />
              <label for="cheminEmploi">Chemin de l'emploi</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div> -->
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <input
                :value="editableItem.cheminEmploi"
                @input="handleInput"
                type="text"
                class="form-control"
                id="cheminEmploi"
                name="cheminEmploi"
                placeholder="cheminEmploi"
              /> -->
              <multiselect
                name="editableItem.cheminEmploi"
                v-model="editableItem.cheminEmploi"
                :options="['SALARIE', 'INDEPENDANT', 'SALARIE_ET_INDEPENDANT']"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Chemin de l'emploi"
                :preselect-first="false"
              />
              <!-- <label for="cheminEmploi">Chemin d'emploi</label> -->
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <multiselect
                v-model="editableItem.typeActiviteAssocies"
                :options="typeActivites"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                label="libelle"
                placeholder="Types d'activités associées"
                :preselect-first="false"
              />
              <!-- <label for="typeActiviteAssocies">Types d'activités associées</label> -->
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ReferentielList,
    Multiselect
  },
  computed: {
    ...mapGetters({
        familles: 'famille/familles',
    }),
    referentielName() {
      return "typeContenu";
    },
    emptyReferentielItem () {
      return {
        libelle: null,
        code: null,
        // famille : null
      }
    },
    tableFields() {
      return [
        "#",
        "index",
        
        { key: "code", label: "Code" },
        { key: "libelle", label: "Libellé" },
        { key: "cheminEmploi", label: "Chemin de l'emploi" },
        "actions",
      ];
    },
   
  },
  created() {
      this.fetchFamilles()
  },
  methods: {
    ...mapActions({
      fetchFamilles: "famille/fetchFamilles",
    }),
  },
};
</script>

<style></style>
